/* Styling for resume page component */

.ph.body {
     max-width: 95%;
     margin: auto;
     margin-top: 2%;

     overflow-y: hidden;
}

/* Photo and wrapper styling */
.ph.photo-wrapper {
     cursor: pointer;
     overflow: hidden;
     position: relative;
}

     .ph.photo-wrapper:hover {
          scale: 1.05;
          z-index: 10;
          transition: scale .6s;
     }

.ph.photo {
     border: 2px solid var(--secondary-col);
}


/* Theater Photo */

.ph.theater.container-fluid {
     position: fixed;
     top: 0; left: 0;
     height: 100vh;
     width: 100%;

     z-index: 100;
     background-color: transparent;
}

.ph.theater.wrapper {
     margin: auto;
     width: 100%;
     height: 100vh;
     
}

.ph.theater.wrapper > * {
     margin: auto;
     
}

.ph.theater {
     max-width: 100%;
     max-height: 80vh;
     display: flex;
     position: relative;
}

 .ph.theater > * {
      padding: 0;
 }

.ph.theater > img {
     object-fit: contain;
     max-width: 100%;
     border: none;
}
.ph.theater > .arrow {

     font-size: 4em;
     font-weight: bold;

     -webkit-transform: scale(1, 2.5);
    -moz-transform: scale(1, 2.5);
    -o-transform: scale(1, 2.5);
    
    opacity: 0;
    transition: opacity 1s;

}

.ph.theater > .arrow:hover {
     cursor: pointer;
     opacity: 0.9;
     background-color: var(--primary-color);
     transition: opacity 1s;
}