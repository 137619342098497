/* Styling for resume page component */

.res-center-col > .row {
    width: 70%;
    margin-left: auto; margin-right: auto;
}

.res-header-row {
    font-size: 0.6em;
}


.res-body-row {
    margin-top: 1%;
    height: 70vh;

    border-bottom: 2px solid white;
}


.resume-wrapper.res {
    max-height: 68vh;
    overflow-y: scroll;
}

.resume-wrapper::-webkit-scrollbar {
    background-color: transparent;
}

.res.download-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
}

.res.download-wrapper > * {
    font-size: 1.5em;
}

.res-child-border > canvas {
    border: 4px solid black;
    border-radius: 15px;
}

/* Mobile Styling */

.container-fluid.mob-res > * {
    align-items: center;
    text-align: center;
}


.mob-res.body {
    margin-top: 5%;
    margin-bottom: 15%;
}

.mob-res.body > .col > * {
    margin-left: auto;
    margin-right: auto;
}

.mob-res.text {
    margin-top: 5%;
    font-size: 1.2em;
}

.download-wrapper {
    max-width: fit-content;

    margin-top: 10px;
    border: 3px solid var(--secondary-col);
    border-radius: 10px;
}

    .download-wrapper:hover {
        background-color: var(--trans-grey);
    }

.download {
    color: var(--prim-font-col);
    text-decoration: none;
}

    .download:visited {
        color: inherit;
    }

.resume-wrapper.mob-res {
    margin-top: 10%;
}