/* 
Styling for major components on homepage of app 

*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&family=Roboto+Slab:wght@400;600&family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

:root {
    --hh-title-font: 'Karla';
}

/* Page Structure */
.center-col {
    text-align: center;
}

.hh-container {
    position: relative;
    min-width: fit-content;
    height: 100%;
}

.hh-outer-row {
    width: 90%;
    min-width: 1200px;
    max-width: 1600px;
    min-height: 800px;
    height: fit-content;

    margin: auto;
}

#hh-spotlight {
    position: absolute;
    height: 100%;
    width: 100%;
    
    pointer-events: none;
}


/* # # # # # */


/* Page Title */
.hh-header-row {
    min-height: 10vh;
    border-bottom: 4px var(--secondary-col) solid;

    padding: 10px;
    padding-top: 0px;
    position: relative;
    z-index: 1;
}

.header-col {
    text-align: left;
    position: relative;
    bottom: 0;
}

.name-title-wrapper {
    position: absolute;
    bottom: 0px;    
}

.name-title, .nf-title { /* same styling for 404 Not Found page */
    /* font-family: 'Roboto Slab', serif; */
    font-family: var(--hh-title-font);
    font-size: 4em;
    letter-spacing: 0.1em;

    -webkit-transform: scale(1, 1.25);
    -moz-transform: scale(1, 1.25);
    -o-transform: scale(1, 1.25);

    margin-bottom: 0;
    line-height: 1.1;

    vertical-align: text-bottom;
}

.quote-wrapper {
    text-align: center;
    font-family: var(--subtitle-font);
}

.header-quote {
    font-style: italic;
    font-size: 1.4em;
    font-weight: 500;
}

.header-quote-attrib {
    font-size: 1.5em;
    font-weight: 600;
}




/* # # # # # */



/* Page Body */
:root {
    --hh-module-grid-height: 450px;
}

.hh-body-row {
    height: 70vh;
    min-height: 800px; 
    width: 84%;
    min-width: 1200px;
    margin: auto;
}

.hh-module-grid-wrapper {
    margin-top: 90px;
    height: var(--hh-module-grid-height);
}

.hh-module-grid-wrapper > .row {
    min-height: calc(var(--hh-module-grid-height)/2);
}

.hh-module-grid-wrapper > .row > .col {
    position: relative;
}


/* # # # # # */


/* Modules */
:root {
    --hh-module-font-size: 2.2em;
        --hh-module-title-offset: 2.4;   /* Distance title is offset from top of div in units of -hh-title-font-size */

    --hh-module-img-w: 240px;
    --hh-module-img-h: 130px;
}

    @media  only screen and (max-width: 860px) {
        :root {
        --hh-module-img-w: 300px;
        --hh-module-img-h: 170px;

        --hh-module-font-size: 2.4em;
            --hh-module-title-offset: 2.55;
        }
    }

.hh-module-wrapper {
    height: 100%;
    width: 80%;
    margin: auto;

    text-align: center;
    position: relative;
    font-size: var(--hh-module-font-size);
}

.hh-image-wrapper > div {
    --hh-zoom: 1.1;
    transform:scale(var(--hh-zoom));
    -ms-transform:scale(var(--hh-zoom)); /* IE 9 */
    -moz-transform:scale(var(--hh-zoom)); /* Firefox */
    -webkit-transform:scale(var(--hh-zoom)); /* Safari and Chrome */
    -o-transform:scale(var(--hh-zoom)); /* Opera */
    transition: transform .7s;
}

.hh-image-wrapper-hover > div {
    --hh-zoom: 1.3;
    transform:scale(var(--hh-zoom));
    -ms-transform:scale(var(--hh-zoom)); /* IE 9 */
    -moz-transform:scale(var(--hh-zoom)); /* Firefox */
    -webkit-transform:scale(var(--hh-zoom)); /* Safari and Chrome */
    -o-transform:scale(var(--hh-zoom)); /* Opera */
    transition: transform .7s;
}


.hh-module-wrapper:hover {
    cursor: pointer;
    z-index: 11;
}

.hh-image-wrapper {
    width: var(--hh-module-img-w);
    height: var(--hh-module-img-h);

    border: 4px solid var(--secondary-col);
    border-radius: 18px;
    
    position: absolute;
    top: calc(var(--hh-module-font-size)*.7);
    overflow: hidden;
    left: 0; right: 0;
    margin: auto;
}

.hh-image-wrapper > div  {
    border: 4px solid black;
    border-radius: 18px;
    background-repeat: no-repeat;

    position: relative;
    background-size: cover;
    z-index: 0;
}

.hh-title-wrapper {
    font-family: var(--subtitle-font);
    font-weight: 600;
    letter-spacing: 1px;

    position: absolute;
    left: 0; right: 0;
    top: calc(var(--hh-module-font-size)*var(--hh-module-title-offset));
    margin: auto;
}

.hh-title-wrapper-hover {
    top: 0;
    animation: hh-fadein 1.5s;
        -webkit-animation: hh-fadein 1s;
}

    @keyframes hh-fadein {
        0% { opacity: 0 }
        100% { opacity: 1 }
    }

/* # # # # # */

/* Drop Down */
:root {
    --hh-module-dd-table-pad: 15px;
}

.hh-drop-down-wrapper-div {
    display: none;

    position: absolute;
    width: 78%;
    max-height: 180px;
    top: calc(var(--hh-module-font-size)*var(--hh-module-title-offset));
    left: 0; right: 0;
    margin: auto;
    margin-top: 2%;
    
    text-align: left;
    font-family: var(--subtitle-font);
    padding-left: var(--hh-module-dd-table-pad);
}

.hh-dd-hover {
    display: block;
}

.hh-drop-down-table {
    margin: auto;
    width: 100%;

    border-collapse: separate;
    padding-right: var(--hh-module-dd-table-pad);
}

.hh-drop-down-table > tbody > tr > td {
    background-color: var(--tert-col);
    font-size: 0.7em;
    font-weight: 500;

    border: 2px var(--secondary-col) solid;

    text-align: center;
}

.hh-drop-down-table > tbody {
    perspective: 1000px; /* 3D animation effect */
}


.hh-drop-down-table > tbody > tr > td > div {
    position: relative;
}


.hh-drop-down-table > tbody > tr > td > div::before {
    content: "=";
    position: absolute;
    left: 4px;
    top: -9px;

    font-weight: 1000;
    font-size: 1.5em;
}

/* # # # # # */

/* DD Scroll bar customs */
.hh-drop-down-wrapper-div {
    overflow-y: scroll;
    overflow-x: hidden;
}

.hh-drop-down-wrapper-div::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    background-color: transparent;
    opacity: 0;
}


.hh-drop-down-wrapper-div::-webkit-scrollbar-track {
    background-color: transparent;
}


/* Handle */   

.hh-drop-down-wrapper-div::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
    border-radius: 20px;
}

.hh-drop-down-wrapper-div {
    overflow: auto;
    -webkit-text-fill-color: var(--prim-font-col);
    color: transparent;
    transition: color .8s ease;
  }

  .hh-drop-down-wrapper-div:hover {
    color: var(--secondary-col);
  }

.hh-drop-down-wrapper-div:hover::-webkit-scrollbar-thumb {
    animation: fade-scroll-in 1s ease-in;
    animation-fill-mode: forwards;
}

/* # # # # # */

/* dd anims */

@keyframes hh-td-wobble {
    0% { 
        transform: rotateZ(0deg);
    }
    25% {
        transform: rotateZ(-3deg);
    }
    75% { 
        transform: rotateZ(3deg);
    }
    100% { 
        transform: rotateZ(0deg);
    }
  }

  .hh-dd-row:hover {
      line-height: 1.6;
      font-size: 1.1em;
      font-family: 'Open Sans';
      -webkit-text-fill-color: var(--primary-col);
  }

  .hh-dd-row:hover > td {
    animation: hh-td-wobble 0.8s infinite;
    border-width: 4px solid var(--secondary-col);
  }

  .hh-dd-row:hover > td > div::before {
    content: "";
}


.footer-row {
    position: relative;
    z-index: 1;
}

/* # # # # # */