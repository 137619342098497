/* Mobile Styling */

.mobile.container-fluid {
    align-items: center;
    overflow-x: hidden;
    min-width: 400px;
}

    @media only screen and (max-width: 720px) {
        .hh-mobile-body { width: 100%; }  
    }

.mobile.container-fluid > div {
    width: 80%;
}

    @media only screen and (max-width: 720px) {
        .mobile.container-fluid > div { width: 90%;}  
    }

.mobile-name-title-wrapper {
    min-height: 20vh;
    height: fit-content;

    width: 100%;
    margin: auto;

    text-align: center;
}

.mobile-name-title {
    margin-top: 5%;
    font-size: 6em;
    font-weight: 500;
    letter-spacing: 0.1em;

    -webkit-transform: scale(1.1, 1);
    -moz-transform: scale(1.1, 1);
    -o-transform: scale(1.1, 1);

    margin-bottom: 0;
    line-height: 1;

    vertical-align: text-bottom;
}

.mobile-name-title > :nth-child(1) {
   line-height: 0.8;
}

.mobile-name-title > :nth-child(4) {
    height: 40px;
    position: relative;
    bottom: 10%;

    letter-spacing: -15px;
 }

    @media only screen and (max-width: 580px) {
        .mobile-name-title > :nth-child(4) {
            letter-spacing: -10px;
            font-size: 0.8em;
        }  
    }

    @media only screen and (max-width: 580px) {
        .mobile-name-title {
            margin-top: 10%; 
            font-size: 5.5em;
        }  
    }


/* Mobile Body + Modules */

    .hh-mobile-body {
        min-height: 40vh;
        width: 90%;
        flex-grow: 1;

        margin: auto;
        justify-content: center;
        position: relative;

        --hh-mobile-arrow-sz: 50px;
    }

        @media only screen and (max-width: 580px) {
            .hh-mobile-body { width: 100%;}  
        }

        @media only screen and (max-width: 450px) {
            :root {
                --hh-module-img-w: 240px;
                --hh-module-img-h: 136px;
        
                --hh-module-font-size: 2.4em;
                    --hh-module-title-offset: 2.2;
            }
            .hh-mobile-body {
                --hh-mobile-arrow-sz: 40px;
            }
        }
    

    .hh-mobile-body > div {
        padding-left: 0;
        padding-right: 0;
    }

    .mobile-footer-row {
        height: 5vh;
        margin: auto;
    }

    .hh.mobile-arrow {
        position: absolute;
        z-index: 12;
        width: 100%;
    }

    .hh.mobile-arrow > div {
        position: relative;
        top: calc(var(--hh-module-img-h)/2 + var(--hh-mobile-arrow-sz)/4);
    }

    .hh.mobile-arrow.arrow-btn-left {
        left: 0;
    }

    .hh.mobile-arrow.arrow-btn-right { left: calc(100% - var(--hh-mobile-arrow-sz)); }
    @media only screen and (max-width: 450px) {
        .hh.mobile-arrow.arrow-btn-right { left: calc(100% - var(--hh-mobile-arrow-sz)/2); }
        .hh.mobile-arrow.arrow-btn-left { left: calc(-1*var(--hh-mobile-arrow-sz)/2); }
    }

    .arrow-left {
        width: 0; 
        height: 0; 
        border-top: var(--hh-mobile-arrow-sz) solid transparent;
        border-bottom: var(--hh-mobile-arrow-sz) solid transparent;

        border-right: var(--hh-mobile-arrow-sz) solid var(--secondary-col);
    }

    .arrow-right {
        width: 0; 
        height: 0; 
        border-top: var(--hh-mobile-arrow-sz) solid transparent;
        border-bottom: var(--hh-mobile-arrow-sz) solid transparent;

        border-left: var(--hh-mobile-arrow-sz) solid var(--secondary-col);
    }
        .hh.mobile-arrow > div:hover {
            cursor: pointer;
            transition: border-left 0.6s, border-right 0.6s;
            
        }
        .arrow-left:hover { border-right: var(--hh-mobile-arrow-sz) solid var(--tert-col); }
        .arrow-right:hover { border-left: var(--hh-mobile-arrow-sz) solid var(--tert-col); }

    /* Carousel Classes */

    .hh-carousel {
        list-style: none;
        width: 100%;
        max-width: 490px;
        position: relative;
        top: 0;
        overflow: hidden;
    }

    .hh-slide {
        width: 100%;
        position: absolute;
        z-index: -11;
        pointer-events: none;
    }

   

    .hh-slide.selected {
        height: 100%;
        width: 100%;

        z-index: 11;
        pointer-events: all;
    }

    .hh-dd-hover {
        position: relative;
        z-index: 11;
    }

    :root { --hh-slide-offset: 250px; --hh-slide-offset: 400px; --hh-slide-time: 2s;}
    .slide-left { animation: slide-left var(--hh-slide-time); -webkit-animation: slide-left var(--hh-slide-time); }
    .slide-right { animation: slide-right var(--hh-slide-time); -webkit-animation: slide-right var(--hh-slide-time); }
    .slide-right-out { animation: slide-right-out var(--hh-slide-time); -webkit-animation: slide-right-out var(--hh-slide-time); }
    .slide-left-out { animation: slide-left-out var(--hh-slide-time); -webkit-animation: slide-left-out var(--hh-slide-time); }

        @keyframes slide-left {
            0% {left: var(--hh-slide-offset) }
            99% { left: calc(-1 * var(--hh-slide-offset));}
            100% {position: static; }
        }

        @keyframes slide-right {
            0% {left: calc(-1 * var(--hh-slide-offset)) }
            99% {left: var(--hh-slide-offset);}
            100% {position: static; }
        }

        @keyframes slide-right-out {
            0% {left: 0; z-index: 0;}
            100% { left: calc(var(--hh-slide-offset)*2); z-index: 0;}
        }

        @keyframes slide-left-out {
            0% {left: 0; z-index: 0; }
            100% { left: calc(-1 * var(--hh-slide-offset) * 2); z-index: 0;}
        }

/* Mobile Adjustments */
