/* General Styling for components that appear in various pages */

:root {
    --primary-col: black;
        --primary-col-trans: rgba(255, 80, 10, 0.3);
    --secondary-col: white;
        --secondary-col-trans: rgba(255, 255, 255, 0.3);
    --tert-col: #767676;

    --prim-font-col: rgb(255, 255, 255);
    --sec-font-col: var(--primary-col);

    --trans-grey: rgba(196, 196, 196, 0.2);
    --very-trans-grey: rgba(196, 196, 196, 0.8);
    --med-grey: rgba(48,48,48);
    --heavy-grey: rgba(18,18,18);
    --very-heavy-grey: rgba(6,6,6);

    --error-red: red;
    --check-green: rgb(11, 211, 11);

    --subtitle-font:  'Calibri', 'Trebuchet MS', 'sans-serif';

    --mobile-lg: 720px; /* CSS vars cannot be used in media query definitions yet */
    --mobile-md: 580px;
    --mobile-sm: 450px;
}

/* Index Styling */
body {
    font-family: "Times New Roman", Times, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

/* Most General Styling */
.container-fluid {
    background-color: var(--primary-col);
    color: var(--prim-font-col);
    height: fit-content;
    min-height: 100vh;
}

.container-fluid > .row {
    width: 100%;
}

/* header footer div */
.header-row {
    margin-top: 3%;
    border-bottom: 4px var(--secondary-col) solid;
}

.small-header-row {
    margin-top: 1%;
    border-bottom: 3px var(--secondary-col) solid;
    width: 100% !important;
}

.small-header-col {
    font-size: .6em;
    text-align: center;
    cursor: pointer;
}

.svg-home {
    margin-bottom: 10%;
}

    @media only screen and (max-width: 720px) {
        .small-header-row {
            margin-top: 4%;
        }
        .small-header-col {
            font-size: .4em;
            text-align: center;
        }
    }

/* END HEADER SPECS */


/* Main Center div, by id */

#shadowed-box {
    position: absolute;
    height: 100%;
    width: inherit;
    
    background-color: var(--primary-col);
    opacity: 0.7;
    pointer-events: none;
}

#shadowed-box.ph {
    opacity: 0.8;
}
/* Hyperlink Buttons */


/* Utility */

.no-padding {
    padding: 0;
}

.no-scroll {
    overflow: hidden !important;
}

.no-internal-padding > * {
    padding: 0 !important;
}

.fit-content {
    width: fit-content !important;
}

.no-flex {
    flex: 0 0 !important;
}

.no-internal-flex > * {
    flex: 0 0 !important;
}

.no-row-star > * {
    width: fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
    flex: 0 0 !important;
}

.child-m-auto > * {
    margin: auto !important;
}

/*
    LIST OF Z-indices

    TOP: 1000
    DropDown Base: 800


    pt-fixed rolling divs: -100
*/